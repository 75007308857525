/* Default appearance of email. */
.email {
    width: 90%;
    padding: 14px 14px;
    font-size: medium;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
}
/* Default highlight around input when selected (focus). */
input[type=text]:focus, input[type=text]:valid:focus, textarea:focus {
    outline: none !important;
    border: 4px solid #c0dcf7;
    box-shadow: 0 0 10px #c0dcf7;
}
/* Handle the scenario where an input value (e.g., email) is selected from a cached list. */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 28px #ffffff inset !important;
}

.left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menu {
    font: 400 24px "Montserrat";
    color: #a0a0a0;
    text-decoration: none;
}

.logo {
    /*width: 100%;*/
    height: 43px;
    object-fit: cover;
}

.about {
    padding-left: 100px;
    padding-top: 160px;
    position: 'absolute';
    width: 450px;
    height:'100%';
    background-color:'#ffffff00';
    visibility: 'visible';
    font: 400 18px "Montserrat";
    color: #0e0e0e;
    z-index: -1;
}

.banner {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.footer {
    position: absolute;
}

.season {
    width: 75%;
    font: 400 18px "Montserrat";
    color: #404040;
    margin: 0 auto 5px;
}

.error {
    padding: 0px;
    margin: 0px;
    word-wrap: break-word;
    white-space: normal;
    color: #8b0015;
}

.status {
    font: 800 14px "Montserrat";
    color: #8b0015;
    margin: 0 auto 5px;
}

.product {
    font: 400 14px "Montserrat";
    color: #000000;
    margin: 0 auto 5px;
    width: 70%;
}

.products {
    font: 400 14px "Montserrat";
    color: #000000;
    display: flex;
}

.productsheaderimage {
    width: 450px;
}

.productsbody {
    font: 400 14px "Montserrat";
    color: #000000;
    margin: 0px 5px;
    display: flex;
    flex-direction: column;
}

.productsbodyimage {
    width: 200px;
}

.detailtitle {
    font: 400 18px "Montserrat";
    color: #000000;
    margin: 5px 5px;
}

.detail {
    font: 400 14px "Montserrat";
    color: #000000;
    margin: 5px 5px;
    white-space: nowrap;
    display: flex;
}

.detailimage {
    width: 450px;
}

.pricing {
    font: 400 14px "Montserrat";
    color: #000000;
}

.pricingtotal {
    font: 400 16px "Montserrat";
    color: #000000;
    white-space: nowrap;
}

.cart {
    font: 400 14px "Montserrat";
    color: #000000;
    margin: 0px 20px;
    white-space: nowrap;
}

.cartmenu {
    position: relative;
    text-align: center;
    font: 400 14px "Montserrat";
    color: #ffffff;
    text-decoration: none;
}

.cartempty {
    font: 400 18px "Montserrat";
    color: #000000;
    margin: 20px 0px;
    white-space: nowrap;
}

.checkout {
    width: 50%;
}

.checkingout {
    font: 400 18px "Montserrat";
    color: #000000;
    margin: 20px 0px;
    white-space: nowrap;
}

.circle {
    position: absolute;
    top: -75%;
    left: 25%;
    background-color: #000000;
    padding-top: 1px;
    padding-left: 1px;
    padding-bottom: 1px;
    padding-right: 1px;
    height: 15px;
    width: 18px;
    border-radius: 100%;
    border: none;
    outline: none;
    cursor: pointer;
}  

.rectangle {
    background-color: #000000;
    color: #f0f0f0;
    padding: 0px 0px;
    height: 40px;
    width: 150px;
    border-radius: 4px;
    border: none;
    outline: none;
    margin: 0px 0px;
    cursor: pointer;
}

/* 8K support. */
@media screen and (max-width: 8096px) {
    .logo {
        height: 43px;
    }
    .menu {
        font: 400 20px "Montserrat";
        color: #a0a0a0;
        margin: 0 auto 5px;
    }
    .banner {
        margin: 0px auto 60px;
    }
    .product {
        width: 90%;
    }
}
/* 4K support. */
@media screen and (max-width: 4096px) {
    .banner {
        margin: 10px auto 40px;
    }
    .product {
        width: 80%;
    }
}
/* 1080p support. */
@media screen and (max-width: 1080px) {
    .banner {
        margin-bottom: 20px auto 40px;
    }
    .product {
        width: 75%;
    }
}
/* 720p support. */
@media screen and (max-width: 720px),
       /* Helps footer position (e.g., Details page). */
       screen and (max-height: 800px) {
    .banner {
        margin: 40px auto 30px;
    }
    .footer {
        position: relative;
    }
}
/* Mobile. */
@media screen and (max-width: 500px) {
    .center {
        flex-direction: column;
    }
    .logo {
        height: 21px;
        object-fit: cover;
    }
    .menu {
        font: 400 16px "Montserrat";
    }
    .about {
        padding-left: 100px;
        padding-top: 150px;
        width: 260px;
        font: 400 16px "Montserrat";
    }
    .banner {
        margin: 10px auto 40px;
    }
    .footer {
        position: relative;
    }
    .detail {
        flex-direction: column;
    }
    .detailimage {
        width: 350px;
    }
    .products {
        flex-direction: column;
    }
    .productsheaderimage {
        width: 350px;
    }
    .productsbody {
        font: 400 14px "Montserrat";
        flex-direction: row;
    }
    .productsbodyimage {
        width: 100px;
    }
    .cart {
        margin: 0px 5px;
    }
    .checkout {
        width: 90%;
    }
}
