/* Animation support. */
@keyframes slideInLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Slider behavior styles. */
.container {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}

.item:last-of-type {
  position: absolute;
  width: 100vw;
}

.item.left {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /*background-color: #ffffff;*/
  /*box-shadow: 0px 5px 5px 5px #c0c0c0;*/
}

.item.hidden {
  display: none;
}

.item.left:first-of-type {
  animation: slideOutLeft 1s ease-in-out;
}

.item.left:last-of-type {
  animation: slideInLeft 1s ease-in-out;
}

.item.right {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item.right:first-of-type {
  animation: slideOutRight 1s ease-in-out;
}

.item.right:last-of-type {
  animation: slideInRight 1s ease-in-out;
}

.item.jump {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Slider image styles. */
.image {
  /*width: 100%;*/
  height: 300px;
  object-fit: cover;
}

/* Slider content styles. */
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: fit-content;
  white-space: pre-wrap;
  max-width: 70%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  color: #000000;
}

.title {
  font: 700 4rem/1.5 "Montserrat";
  margin: 0 auto 30px;
}

.subtitle {
  font: 400 3rem/1.5 "Montserrat";
  margin: 0 auto 30px;
}

.button {
  font: 700 1.5rem/1.5 "Montserrat";
  text-decoration: none;
  color: #ffffff;
  background-color: #a0a0a0;
  padding: 5px 50px;
  border-radius: 5px;
}

.arrow {
  position: absolute;
  transform: translateY(100%);
  /*top: 50%;
  font: 400 3.5rem/1.5 "Montserrat";
  color: #c0c0c0;
  width: fit-content;*/
  background: none;
  border: none;
  cursor: pointer;
}

.arrow_left {
  left: 0px;
}

.arrow_right {
  right: 0px;
}

.scroll {
  height: '250px';
}

.indicators {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.indicator {
  background-color: #000000;
  padding: 0px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555555;
  margin: 0 4px;
  cursor: pointer;
}

.indicator.inactive {
  background-color: #c0c0c0;
}
/* 8K support. */
@media screen and (max-width: 8096px) {
  .image {
    height: 250px;
  }
  .arrow {
    transform: translateY(200%);
  }
  .scroll {
    height: 180px;
  }
}
/* 1080p support. */
@media screen and (max-width: 1080px) {
  .image {
    height: 200px;
  }
  .title {
    font: 700 3rem/1.5 "Montserrat";
    margin: 0 auto 20px;
  }
  .subtitle {
    font: 400 2rem/1.5 "Montserrat";
    margin: 0 auto 20px;
  }
  .button {
    padding: 5px 30px;
  }
  .arrow {
    transform: translateY(150%);
  }
  .scroll {
    height: 150px;
  }
}
/* 720p support. */
@media screen and (max-width: 720px) {
  .image {
    height: 150px;
  }
  .arrow {
    transform: translateY(100%);
  }
  .scroll {
    height: 100px;
  }
}
/* Mobile. */
@media screen and (max-width: 500px) {
  .image {
    height: 100px;
  }
  .arrow {
    transform: translateY(100%);
  }
  .scroll {
    height: 80px;
  }
}
